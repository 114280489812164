<template>
  <div style="padding: 32px">
    <el-table :data="tableData">
      <el-table-column
        label="Institution"
        prop="account_info.account_name"
      ></el-table-column>
      <!-- <el-table-column label="类型" prop="account_info.account_type"></el-table-column> -->
      <el-table-column label="Student Name">
        <template #default="scope">
          {{ scope.row.student_info.student_lastName
          }}{{ scope.row.student_info.student_givenName }}
        </template>
      </el-table-column>
      <el-table-column label="Student Email">
        <template #default="scope">
          {{ scope.row.student_info.student_email
          }}
        </template>
      </el-table-column>
      <el-table-column
        label="EAE Program"
        prop="application_project"
      ></el-table-column>
      <el-table-column label="Registration Status" prop="application_status">
        <template slot-scope="scope">
          {{
            scope.row.application_status === "待缴费"
              ? "Pending Payment"
              : "Registered"
          }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="View">
        <template slot-scope="scope">
          <el-button @click="view(scope.row)" type="primary" size="small"
            >View</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <el-dialog :visible.sync="showD">
      <div style="margin-bottom: 10px">
        <div>Name of Institution</div>
        <div style="font-size: 18px">
          {{ curent.account_info.account_name }}
        </div>
      </div>
      <div style="margin-bottom: 10px">
        <div>Student Name</div>
        <div style="font-size: 18px">
          {{ curent.student_info.student_lastName
          }}{{ curent.student_info.student_givenName }}
        </div>
      </div>
      <div style="margin-bottom: 10px">
        <div>Project Name</div>
        <div style="font-size: 18px">{{ curent.application_project }}</div>
      </div>
      <div style="margin-bottom: 10px">
        <div>Payment Status</div>
        <div style="font-size: 18px">
          {{
            curent.application_status === "待缴费" ? "Pending payment" : "Paid"
          }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getApplicationsByAdvisor } from "../../api/eae";
import { getAccountId } from "../../utils/store";
export default {
  data() {
    return {
      tableData: [],
      curent: { account_info: {}, student_info: {} },
      showD: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getApplicationsByAdvisor({ account_id: getAccountId()}).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.data;
        }
      });
    },
    view(item) {
      this.curent = item;
      this.showD = true;
    },
  },
};
</script>

<style></style>
